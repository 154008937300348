/* eslint-disable xss/no-mixed-html */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  aboutYou: {
    id: 'Auth.AboutYou',
    defaultMessage: 'About you',
  },
  birthday: {
    id: 'Auth.Birthday',
    defaultMessage: 'Birthday',
  },
  confirmEmailAddress: {
    id: 'Auth.ConfirmEmailAddress',
    defaultMessage: 'Confirm email address',
  },
  confirmPassword: {
    id: 'Auth.ConfirmPassword',
    defaultMessage: 'Confirm password',
  },
  congratulations: {
    id: 'Auth.Congratulations',
    defaultMessage: 'Congratulations!',
  },
  day: {
    id: 'Auth.Day',
    defaultMessage: 'Day',
  },
  emailAddress: {
    id: 'Auth.EmailAddress',
    defaultMessage: 'Email address',
  },
  firstName: {
    id: 'Auth.FirstName',
    defaultMessage: 'Firstname',
  },
  forgotYourPassword: {
    id: 'Auth.ForgotYourPassword',
    defaultMessage: 'Forgot your password?',
  },
  gender: {
    id: 'Auth.Gender',
    defaultMessage: 'Gender',
  },
  login: {
    id: 'Auth.Login',
    defaultMessage: 'Log in',
  },
  signInToSaveEvents: {
    id: 'Auth.LoginToSaveEvents',
    defaultMessage: 'Sign in to save events',
  },
  savedEventsLoginBlurb: {
    id: 'Auth.SavedEventsLoginBlurb',
    defaultMessage: 'Create a wishlist of your must attend parties.',
  },
  loginInformation: {
    id: 'Auth.LoginInformation',
    defaultMessage: 'Login information',
  },
  signInToAccount: {
    id: 'Auth.SignInToAccount',
    defaultMessage: 'Sign in to your account',
  },
  notLoggedIn: {
    id: 'Auth.NotLoggedIn',
    defaultMessage: 'Not logged in',
  },
  logout: {
    id: 'Auth.Logout',
    defaultMessage: 'Log out',
  },
  month: {
    id: 'Auth.Month',
    defaultMessage: 'Month',
  },
  newToRA: {
    id: 'Auth.NewToRA',
    defaultMessage: 'New to RA?',
  },
  newToRASignUpForAccount: {
    id: 'Auth.NewToRASignUpForAccount',
    defaultMessage: 'New to RA? Sign up for an account.',
  },
  password: {
    id: 'Auth.Password',
    defaultMessage: 'Password',
  },
  register: {
    id: 'Auth.Register',
    defaultMessage: 'Sign up',
  },
  registrationComplete: {
    id: 'Auth.RegistrationComplete',
    defaultMessage:
      'You’re now registered. You will receive an email - sent to {email} - with an activation link to verify your account.',
  },
  resendVerificationEmailComplete: {
    id: 'Auth.ResendVerificationEmailComplete',
    defaultMessage:
      'You will receive an email - sent to {email} - with an activation link to verify your account.',
  },
  requiredKey: {
    id: 'Auth.RequiredKey',
    defaultMessage: 'required',
    description:
      'Used at the top of forms to indicate what required fields look like',
  },
  signin: {
    id: 'Auth.Signin',
    defaultMessage: 'Sign in',
  },
  submit: {
    id: 'Auth.Submit',
    defaultMessage: 'Submit',
  },
  surname: {
    id: 'Auth.Surname',
    defaultMessage: 'Surname',
  },
  username: {
    id: 'Auth.Username',
    defaultMessage: 'Username',
  },
  usernameOrEmail: {
    id: 'Auth.UsernameOrEmail',
    defaultMessage: 'Username or email',
  },
  email: {
    id: 'Auth.Email',
    defaultMessage: 'Email',
  },
  year: {
    id: 'Auth.Year',
    defaultMessage: 'Year',
  },
  yourDetails: {
    id: 'Auth.YourDetails',
    defaultMessage: 'Your details',
  },
  newsletter: {
    id: 'Auth.Newsletter',
    defaultMessage: 'RA Newsletter',
  },
  registerTermsAndConditionsNew: {
    id: 'Auth.RegisterTermsAndConditionsNew',
    defaultMessage:
      'By registering, you are indicating that you have read and agree to the <terms>Terms of Use</terms>, <purchase>Purchase Policy</purchase> and <privacy>Privacy Policy</privacy>',
  },
  receiveTheLatestNewsAndUpdates: {
    id: 'Auth.ReceiveTheLatestNewsAndUpdates',
    defaultMessage: 'Receive the latest news and updates from Resident Advisor',
  },
  genderDateOfBirthDiversity: {
    id: 'Auth.GenderDateOfBirthDiversity',
    defaultMessage:
      'We ask for your gender and date of birth to help promoters improve diversity & inclusion.',
  },
  alreadyHaveAnAccount: {
    id: 'Auth.AlreadyHaveAnAccount',
    defaultMessage: 'Already have an account?',
  },
  youAlreadyHaveAnAccount: {
    id: 'Auth.YouAlreadyHaveAnAccount',
    defaultMessage: 'You already have an account',
  },
  confirmYourPassword: {
    id: 'Auth.ConfirmYourPassword',
    defaultMessage: 'Confirm your password',
  },
  yourAccount: {
    id: 'Auth.YourAccount',
    defaultMessage: 'Your account',
  },
  confirmPasswordToLinkAccounts: {
    id: 'Auth.ConfirmPasswordToLinkAccounts',
    defaultMessage:
      'You already have an RA account. Confirm your password to link it to your {provider} account.',
  },
  requestPasswordReset: {
    id: 'Auth.RequestPasswordReset',
    defaultMessage: 'Request password reset',
    description: 'Header text on the forgot password page',
  },
  enterDetailsToReset: {
    id: 'Auth.EnterDetailsToReset',
    defaultMessage: 'Enter your email or username to get sent a reset link',
    description: 'Description text on forgot password page',
  },
  send: {
    id: 'Auth.Send',
    defaultMessage: 'Send',
    description: 'Button text for forgot password form',
  },
  resetLinkSent: {
    id: 'Auth.ResetLinkSent',
    defaultMessage: 'Reset link sent',
    description: 'Heading after user has submitted forgot password form',
  },
  resetLinkInstructions: {
    id: 'Auth.ResetLinkInstructions',
    defaultMessage:
      "We've sent you an email with a reset link in it. Click on the link to reset your password",
    description: 'Instructions after user has submitted forgot password form',
  },
  resetLinkCheckSpam: {
    id: 'Auth.ResetLinkCheckSpam',
    defaultMessage: 'Please check your spam folder for the password reset link',
    description:
      'Check spam folder after user has submitted forgot password form',
  },
  setYourNewPassword: {
    id: 'Auth.SetYourNewPassword',
    defaultMessage: 'Set your new password',
    description: 'Header text for reset password page',
  },
  newPassword: {
    id: 'Auth.NewPassword',
    defaultMessage: 'New password',
    description: 'Field label for new password input',
  },
  save: {
    id: 'Auth.Save',
    defaultMessage: 'Save',
    description: 'Set new password button text',
  },
  yourLinkHasExpired: {
    id: 'Auth.YourLinkHasExpired',
    defaultMessage: 'Your link has expired',
  },
  linkExpiredInstructions: {
    id: 'Auth.LinkExpiredInstructions',
    defaultMessage:
      'Enter your account details to resend the verification email',
  },
  resendVerificationEmail: {
    id: 'Auth.ResendVerificationEmail',
    defaultMessage: 'Resend verification email',
  },
  goToHome: {
    id: 'Auth.GoToHome',
    defaultMessage: 'Go to home',
  },
  yourAccountHasBeenVerified: {
    id: 'Auth.YourAccountHasBeenVerified',
    defaultMessage: 'Your account has been verified',
  },
  enterYourPasswordToLogin: {
    id: 'Auth.EnterYourPasswordToLogin',
    defaultMessage: 'Enter your password to log in.',
  },
  myAccount: {
    id: 'Auth.MyAccount',
    defaultMessage: 'My account',
  },
  address: {
    id: 'Auth.Address',
    defaultMessage: 'Address',
  },
  city: {
    id: 'Auth.City',
    defaultMessage: 'City',
  },
  postalCode: {
    id: 'Auth.PostalCode',
    defaultMessage: 'Postal code',
  },
  needToBeLoggedIn: {
    id: 'Auth.NeedToBeLoggedIn',
    defaultMessage: 'You need to be logged in to view this page.',
  },
  oauthUserIsNewTitle: {
    id: 'Auth.OauthUserIsNew',
    defaultMessage: 'Your apple email is not registered',
  },
  oauthUserIsNewSubtitle: {
    id: 'Auth.OauthUserIsNewSubtitle',
    defaultMessage: 'Please sign up for an account',
  },
  oauthUserIsNewErrorText: {
    id: 'Auth.OauthUserIsNewErrorText',
    defaultMessage: 'Apple email not registered',
  },
  oauthGenericErrorText: {
    id: 'Auth.OauthGenericErrorText',
    defaultMessage: 'Something went wrong. Please refresh and try again',
  },
  signInWithAppleText: {
    id: 'Auth.SignInWithAppleText',
    defaultMessage: 'Sign in with Apple',
  },
  continueWithAppleText: {
    id: 'Auth.ContinueWithAppleText',
    defaultMessage: 'Continue with Apple',
  },
  verifyEmailTitle: {
    id: 'Auth.VerifyEmailTitle',
    defaultMessage: 'We need to verify your email',
  },
  verifyEmailText: {
    id: 'Auth.VerifyEmailText',
    defaultMessage:
      "Please check your email and follow the verification link to sign in. If you can't find the email, check your spam folder.",
  },
  signInOrSignUpToBuyTickets: {
    id: 'Auth.SignInOrSignUpToBuyTickets',
    defaultMessage: 'Sign in or sign up to buy tickets',
  },
  signInBuyTickets: {
    id: 'Auth.SignInBuyTickets',
    defaultMessage: 'Sign in to buy your tickets',
  },
  ticketsAreOnHoldForALimitedTime: {
    id: 'Auth.TicketsAreOnHoldForALimitedTime',
    defaultMessage: 'Tickets are on hold for a limited time',
  },
  createANewAccount: {
    id: 'Auth.CreateANewAccount',
    defaultMessage: 'Create a new account',
  },
  enterYourEmailToCompleteVerification: {
    id: 'Auth.EnterYourEmailToCompleteVerification',
    defaultMessage: 'Enter your email to complete verification.',
  },
  resendEmail: {
    id: 'Auth.ResendEmail',
    defaultMessage: 'Resend email',
  },
  resendingFailed: {
    id: 'Auth.ResendingFailed',
    defaultMessage: 'Resending failed',
  },
  lastSentDate: {
    id: 'Pro.LastSentDate',
    defaultMessage: 'Last sent {date}',
  },
  verifyingMagicLink: {
    id: 'Pro.VerifyingMagicLink',
    defaultMessage: 'Verifying magic link...',
  },
  magicLinkExpired: {
    id: 'Pro.MagicLinkExpired',
    defaultMessage: 'Link expired',
  },
  magicLinkExpiredSubtitle: {
    id: 'Pro.MagicLinkExpiredSubtitle',
    defaultMessage:
      'Request a new one or use the magic link from your most recent email.',
  },
  continue: {
    id: 'Pro.Continue',
    defaultMessage: 'Continue',
  },
  pleaseWaitBeforeResending: {
    id: 'Pro.PleaseWaitBeforeResending',
    defaultMessage: 'Please wait {time} before resending.',
  },
})

export default messages
